<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('supplierConnectors') }}</div>
        </div>
        <div class="absolute bg-red-30 inset-0 z-10" @click="modal = false" v-if="modal"></div>

        <div class="mt-3">
            <div class="relative grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden mb-3">
                    <div class="flex md:space-x-4 items-center align-items-center w-full bg-light-teal p-2 border-b">
                        <span class="text-xs text-gray-700 cursor-pointer">Appareal</span>
                        <span class="text-xs text-gray-700 cursor-pointer">Accessories</span>
                        <span class="text-xs text-gray-700 cursor-pointer">Furniture</span>
                        <span class="text-xs text-gray-700 cursor-pointer">Home Appliances</span>
                        <span class="text-xs text-gray-700 cursor-pointer">Electronics</span>
                        <span class="text-xs text-gray-700 cursor-pointer">Foods</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 gap-4">
                    <div class="p-3 flex justify-center w-full border rounded-md border-gray-900">
                        <img src="./../../assets/images/connectors/techdata.png" alt="">
                    </div>
                    <div class="p-3 flex justify-center w-full border rounded-md border-gray-900">
                        <img src="./../../assets/images/connectors/deltaco-logo.png" alt="">
                    </div>
                    <div class="p-3 flex justify-center w-full border rounded-md border-gray-900">
                        <img src="./../../assets/images/connectors/tura.png" alt="">
                    </div>
                    <div class="p-3 flex justify-center w-full border rounded-md border-gray-900">
                        <img src="./../../assets/images/connectors/ingram.png" alt="">
                    </div>
                    <div class="p-3 flex justify-center w-full border rounded-md border-gray-900">
                        <img src="./../../assets/images/connectors/exertis.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Bus from '../../bus'
const country = () => import('./helper/countries')

    export default {
        name : 'Notification',
        data () {
            return {
                countries : [],
                loading : true,
                processing : false,
                countryText : '',
                modal : false,
                countryResults : [],
                form : {
                    first_name : '',
                    last_name : '',
                    email : '',
                    role : '',
                    company : {
                        street_address  : '',
                        city  : '',
                        country  : '',
                        zip_code  : '',
                        company_name  : '',
                        company_number  : '',
                        company_vat_id  : '',
                        email  : '',
                        phone_number  : '',
                    }
                }
            }
        },
        computed : {
            ...mapGetters({
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_REF : 'auth/GET_USER_REF',
                GET_LOGIN_USER : 'jitcontrol/GET_LOGIN_USER',
                GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
                GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
            })
        },
        mounted () {
            
        },
        methods : {
            
        }
    }
</script>

<style lang="scss" scoped>
.table th {
    padding: .3rem;
    vertical-align: top;
    // border-top: 1px solid #dee2e6;
}
.table tbody, td {
    // padding: .7rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}
</style>