<template>
    <main class="p-5 mt-2">
        <div class="flex justify-end items-center align-items-center md:space-x-3">
            <button class="bg-theme-blue text-xs text-white px-4 py-1 rounded-sm shadow-md focus:outline-none cursor-pointer">Export to Excel</button>
            <select class="px-5 py-1 rounded-sm focus:outline-none bg-white border text-xs text-gray-700">
                <option :value="webshop.uuid" v-for="webshop in GET_WEBSHOPS.results" :key="webshop.uuid">{{ webshop.shop_name }}</option>
            </select>
        </div>

        <div class="mt-3">
            <div class="relative grid grid-cols-1 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-4 w-full">
                <div class="col-span-2 flex flex-col border">
                    <div class="head flex justify-between md:space-x-4 items-center align-items-center w-full bg-light-teal p-2 border">
                        <span>Top Customers</span>
                        <span class="text-xs">Total Spent</span>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex justify-center" v-for="i in 20" :key="i">
                            <div class="flex space-x-3 items-center w-full p-2">
                                <span :class="i % 2 === 0 ? 'fi-se':'fi-gb'" class="h-10 w-10 fi text-2xl rounded-full"></span>
                                <span class="flex flex-col">
                                    <span class="text-sm">Tom Jones</span>
                                    <span class="text-xs text-gray-400">{{ i % 2 === 0 ? 'Person' : 'Organization'}}</span>
                                </span>
                            </div>
                            <span class="text-gray-800 font-semibold p-2">$1,850.00</span>
                        </div>
                    </div>
                </div>
                <div class="col-span-3 flex flex-col border">
                    <div class="head flex justify-between md:space-x-4 items-center align-items-center w-full bg-light-teal p-2 border">
                        <span>Top Product Performance</span>
                        <div class="flex space-x-4 justify-between">
                            <span class="text-xs">Total Gross Profit</span>
                            <span class="text-xs">Total Sold</span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="flex justify-center" v-for="i in 20" :key="i">
                            <div class="flex space-x-3 items-center w-full p-2">
                                <span :class="i % 2 === 0 ? 'fi-se':'fi-gb'" class="h-10 w-10 fi text-2xl rounded-full"></span>
                                <span class="text-sm">SKUTC-69788</span>
                                <span class="text-xs text-gray-400">Apple MacBook  Model 2346</span>
                            </div>
                            <span class="flex space-x-4 items-center text-gray-800 font-semibold p-2">
                                <span class="text-sm font-semibold whitespace-nowrap">$1,850.00</span>
                                <span class="text-xs text-gray-400 whitespace-nowrap">5690 units</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Bus from '../../bus'
import "/node_modules/flag-icons/css/flag-icons.min.css"
const country = () => import('./helper/countries')

    export default {
        name : 'Notification',
        data () {
            return {
                countries : [],
                loading : true,
                processing : false,
                countryText : '',
                modal : false,
                countryResults : [],
                form : {
                    first_name : '',
                    last_name : '',
                    email : '',
                    role : '',
                    company : {
                        street_address  : '',
                        city  : '',
                        country  : '',
                        zip_code  : '',
                        company_name  : '',
                        company_number  : '',
                        company_vat_id  : '',
                        email  : '',
                        phone_number  : '',
                    }
                }
            }
        },
        computed : {
            ...mapGetters({
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_REF : 'auth/GET_USER_REF',
                GET_WEBSHOPS : 'jitcontrol/GET_WEBSHOPS',
                GET_LOGIN_USER : 'jitcontrol/GET_LOGIN_USER',
                GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
                GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
            })
        },
        mounted () {
            
        },
        methods : {
            
        }
    }
</script>

<style lang="scss" scoped>
.table th {
    padding: .3rem;
    vertical-align: top;
    // border-top: 1px solid #dee2e6;
}
.table tbody, td {
    // padding: .7rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}
</style>