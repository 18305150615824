<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('userManagement') }}</div>
            <!-- <span class="bg-theme-yellow px-3 py-2 rounded-md text-white">Add Webshop</span> -->
        </div>
        <div class="mt-3">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between items-center align-items-center w-full bg-light-teal p-2 border-b">
                        <span class="text-sm font-semibold">{{ $t('inviteUser') }}</span>
                        <!-- <span @click="addNewUser" class="bg-theme-blue cursor-pointer text-sm px-3 py-1 rounded-md shadow-md text-white">
                            <span v-if="processing">Adding...</span>
                            <span v-else>Add New User</span>
                        </span> -->
                    </div>
                    <div class="flex flex-col md:flex-row w-full">
                        <form @submit.prevent="addNewUser" class="flex flex-col w-full md:w-1/2 mb-4">
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('firstName') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.firstname" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('lastName') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="text" v-model="form.lastname" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('email') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <input type="email" v-model="form.email" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2">{{ $t('role') }} <span class="text-red-500">*</span></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <select v-model="form.role" class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;" required>
                                        <option selected value="" disabled>---</option>
                                        <option value="SuperUser">Superuser</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Employee">{{ $t('employee') }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:justify-between md:items-center md:w-3/4 p-2">
                                <span class="text-sm text-gray-600 w-1/2 mb-2"></span>
                                <div class="form-group w-full md:w-3/4 pr-3 md:pr-0">
                                    <button :disabled="processing" class="focus:outline-none bg-theme-blue text-sm px-3 py-1 rounded-md shadow-md text-white">
                                        <span v-if="processing">{{ $t('pleaseWait')}}...</span>
                                        <span v-else>{{ $t('inviteUser') }}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div class="flex flex-col w-full md:w-1/2"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-10 mb-20" v-if="showUsers">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between items-center w-full bg-light-teal p-1 border-b">
                        <h2 class="text-sm font-semibold">{{ $t('userOverview') }}</h2>
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
                            <div>
                                <!-- <input placeholder="Search" type="text" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;"> -->
                                <select class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                                    <option selected disabled>{{ $t('sortBy') }}</option>
                                    <!-- <option value="order-asc">Order Date (ASC)</option>
                                    <option value="order-desc">Order Date (DESC)</option>
                                    <option value="amount-asc">Amount (ASC)</option>
                                    <option value="amount-desc">Amount (DESC)</option> -->
                                </select>
                            </div>
                            <!-- <div>
                                <input placeholder="Search" type="text" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                            </div> -->
                            <div>
                                <input :placeholder="$t('search')" type="text" class="w-full rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs" style="outline: currentcolor none medium;">
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full">
                        <div class="block w-full overflow-x-auto md:overflow-x-hidden overflow-y-hidden">
                            <table class="table md:table-fixed w-full mb-10" border="4">
                                <thead>
                                    <tr>
                                        <!-- <th width="30px" class="text-left whitespace-nowrap p-2 md:p-0">
                                            <input type="checkbox" class="checkbox">
                                            <span class="text-xs text-gray-400"></span>
                                        </th> -->
                                        <th width="30px" class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">ID</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('name') }}</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('role') }}</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('email') }}</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('dateCreation') }}</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('dateModified') }}</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">{{ $t('status') }}</span></th>
                                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Action</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-xs" v-for="(user, i) in GET_LINKED_USERS.results" :key="user.id">
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-gray-500">{{ parseInt(i + 1) }}</span></td>
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-gray-500">{{ $services.helpers.capitalizeEachString(user.user.first_name + ' ' + user.user.last_name) }}</span></td>
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-gray-500">{{ user.role }}</span></td>
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-gray-500">{{ user.user.email }}</span></td>
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-gray-500">{{ user.created_on.split('T')[0] }}</span></td>
                                        <td><span class="whitespace-nowrap p-2 md:p-0 text-gray-500">{{ user.updated_on.split('T')[0] }}</span></td>
                                        <td>
                                            <span class="whitespace-nowrap bg-yellow-500 px-2 py-1 rounded-md text-white" v-if="user.status === 'Invited'">{{ $t('invited') }}</span>
                                            <span class="whitespace-nowrap bg-green-500 px-2 py-1 rounded-md text-white" v-if="user.status === 'Connected'">{{ $t('connected') }}</span>
                                        </td>
                                        <td>
                                            <span class="flex items-center cursor-pointer space-x-3 font-semibold whitespace-nowrap p-2 md:p-0 text-sm text-gray-400">
                                                <span class="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                                                    </svg>
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Bus from '../../bus'

    export default {
        name : 'Users',
        data () {
            return {
                showUsers : false,
                processing : false,
                form : {
                    firstname : '',
                    lastname : '',
                    email : '',
                    role : '',
                },
                allUsers : [],
                companyResponse : []
            }
        },
        computed : {
            ...mapGetters({
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
                GET_LINKED_USERS : 'jitcontrol/GET_LINKED_USERS'
            })
        },
        mounted () {
            this.getUsers()
            this.getUserInformation()
        },
        methods : {
            getUserInformation () {
                this.loading = true
                this.$store.dispatch('jitcontrol/getUserInfo')
                .then(_ => { 
                    if (this.GET_USER_INFORMATION.length > 0) {
                        const payload = {
                            company : this.GET_USER_INFORMATION[0].company ? this.GET_USER_INFORMATION[0].company.uuid : this.GET_USER_INFORMATION[0].user.company.uuid
                        }
                        if (this.GET_USER_INFORMATION[0].role === 'SuperUser' || this.GET_USER_INFORMATION[0].role === 'Admin') {
                            this.showUsers = true
                            this.getLinkedUsers(payload)
                        }
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getUserInformation()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            getUsers () {
                const payload = {
                    URL : this.$services.endpoints.GET_LOGIN_USER
                }
                this.$store.dispatch('jitcontrol/customGetRequest', payload)
                .then(res => {
                    this.companyResponse = res.data.results
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getUsers()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            getLinkedUsers (data) {
                const payload = {
                    URL : this.$services.endpoints.GET_LOGIN_USER + '/' + data.company + '/users/linked_users',
                    users : true
                }
                this.$store.dispatch('jitcontrol/customGetRequest', payload)
                .then(res => {
                    // console.log('what isres:::', res.data)
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                        .then(_ =>{
                            this.getLinkedUsers(data)
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            Bus.emit('sign-out')
                        })
                    }
                })
            },
            addNewUser () {
                if (this.form.firstname === '' || this.form.lastname === '' || this.form.email === '' || this.form.role === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (!this.$services.helpers.validateEmail(this.form.email)) {
                    this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
                } else {
                    if (this.GET_USER_INFORMATION[0].role === 'SuperUser' || this.GET_USER_INFORMATION[0].role === 'Admin') {
                        this.processing = true
                        const payload = {
                            email : this.form.email,
                            first_name : this.form.firstname,
                            last_name : this.form.lastname,
                            role : this.form.role,
                        }
                        this.$store.dispatch('jitcontrol/userInvite', { payload, uuid : this.companyResponse[0].company.uuid })
                        .then(_ => {
                            this.processing = false
                            this.$services.helpers.notification(this.$t('invitationSent'), 'success', this)
                            Object.keys(this.form).forEach(el => { this.form[el] = '' })
                        })
                        .catch(err => {
                            this.processing = false
                            if (err.response.status === 401) {
                                this.$store.dispatch('auth/refreshToken', { refresh : this.USER_REFRESH })
                                .then(_ =>{
                                    this.addNewUser()
                                })
                                .catch(_ => {
                                    this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                    Bus.emit('sign-out')
                                })
                            }
                            if (err.response.status === 400) {
                                this.$services.helpers.notification(err.response.data, 'error', this)
                            }
                        })
                    } else {
                        this.$services.helpers.notification(this.$t('permissionDenied'), 'error', this)
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>