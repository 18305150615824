<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('notify') }}</div>
        </div>
        <div class="absolute bg-red-30 inset-0 z-10" @click="modal = false" v-if="modal"></div>

        <div class="mt-3">
            <div class="relative grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden mb-3">
                    <div class="flex md:space-x-4 items-center align-items-center w-full bg-light-teal p-2 border-b">
                        <div>
                            <select class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                                <option value="##">Sort by</option>
                            </select>
                        </div>
                        <div>
                            <select class="w-full bg-white rounded p-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs">
                                <option value="##">Filter</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <table class="table table-auto w-full" border="4">
                    <thead>
                        <tr>
                            <th width="30px" class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-gray-400"><input type="checkbox" class="checkbox"></span></th>
                            <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">id</span></th>
                            <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Element number</span></th>
                            <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Message</span></th>
                            <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Shop</span></th>
                            <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Event Type</span></th>
                            <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Created</span></th>
                            <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Actions</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="i in 7" :key="i">
                            <td>
                                <input type="checkbox" class="checkbox">
                            </td>
                            <td><span class="text-xs text-gray-700">#104</span></td>
                            <td><span class="text-xs text-gray-700">Order 349687</span></td>
                            <td><span class="text-xs text-gray-700">Tracking number could not be posted</span></td>
                            <td><span class="text-xs text-gray-700">Verbit</span></td>
                            <td><span class="text-xs text-blue-500 font-semibold">ACTION</span></td>
                            <td><span class="text-xs text-gray-700">17:59 2022-05-25 </span></td>
                            <td>
                                <span class="mr-2 cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                                    </svg>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Bus from '../../bus'
const country = () => import('./helper/countries')

    export default {
        name : 'Notification',
        data () {
            return {
                countries : [],
                loading : true,
                processing : false,
                countryText : '',
                modal : false,
                countryResults : [],
                form : {
                    first_name : '',
                    last_name : '',
                    email : '',
                    role : '',
                    company : {
                        street_address  : '',
                        city  : '',
                        country  : '',
                        zip_code  : '',
                        company_name  : '',
                        company_number  : '',
                        company_vat_id  : '',
                        email  : '',
                        phone_number  : '',
                    }
                }
            }
        },
        computed : {
            ...mapGetters({
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_REF : 'auth/GET_USER_REF',
                GET_LOGIN_USER : 'jitcontrol/GET_LOGIN_USER',
                GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
                GET_CHOOSEN_COMPANY : 'jitcontrol/GET_CHOOSEN_COMPANY'
            })
        },
        mounted () {
            
        },
        methods : {
            
        }
    }
</script>

<style lang="scss" scoped>
.table th {
    padding: .3rem;
    vertical-align: top;
    // border-top: 1px solid #dee2e6;
}
.table tbody, td {
    // padding: .7rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}
</style>