<template>
    <div class="flex flex-col w-full border rounded-md p-3 mb-2">
        <div class="flex items-center justify-between">
            <h2 class="font-semibold text-lg">Question</h2>
            <div>
                <span v-if="open" class="cursor-pointer" @click="open = false">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-900 font-semibold" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                    </svg>
                </span>
                <span v-else class="cursor-pointer" @click="open = true">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-900 font-semibold" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                </span>
            </div>
        </div>
        
        <div v-if="open" class="mt-1 text-sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint excepturi quibusdam tenetur blanditiis animi nemo ipsa dolor provident praesentium! Ex porro magni fuga provident facilis adipisci deleniti, neque reiciendis. Beatae!
        </div>
    </div>
</template>

<script>
    export default {
        name : 'RenderListFAQ',
        data () {
            return {
                open : false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>