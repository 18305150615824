<template>
    <main class="p-5 mt-2">
        <div class="flex flex-col p-10 bg-white h-screen rounded-md">
            <div class="flex justify-end" v-if="GET_USER_INFORMATION && GET_USER_INFORMATION.length > 0">
                <span class="flex space-x-2 cursor-pointer" v-if="GET_USER_INFORMATION[0].role === 'SuperUser'">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    </span>
                    <span @click="openFaqModal = true" class="text-sm uppercase font-semibold">Add FAQ's</span> 
                </span>
            </div>
            <div class="flex flex-col justify-center w-full">
                <h1 class="text-4xl font-semibold text-center">Frequent Asked Questions</h1>
                <div class="text-sm text-gray-400 text-center md:px-40 mt-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veritatis maxime voluptas in, expedita minima quo iusto laudantium beatae tempore eos!</div>
            </div>
            <div class="flex flex-col mt-20">
                <h3 class="text-gray-800 font-semibold mb-5">Categories</h3>
                <div class="grid grid-cols-1 md:grid-cols-9 gap-4 mb-2 text-xs">
                    <span class="cursor-pointer">Fortnox</span>
                    <span class="cursor-pointer">Good to know</span>
                    <span class="cursor-pointer">Visma</span>
                    <span class="cursor-pointer">Karla</span>
                </div>
                <div class="flex flex-col">
                    <FaqRender v-for="item in 6" :key="item" />
                </div>
            </div>
            
        </div>
    </main>
    <ModalBox v-if="openFaqModal" title="Add Frequently Asked Questions"  @handleClose='openFaqModal = false' :backdropOff='false'>
        <div class="flex flex-col w-full overflow-x-hidden">
            <form class="flex flex-col">
                <div class="flex flex-col mb-5">
                    <label for="question" class="text-sm text-gray-700 mb-3 font-semibold">Question</label>
                    <input type="text" placeholder="Type question here" class="text-gray-400 focus:outline-none px-3 py-3 border border-gray-200 focus:border-theme-blue rounded-md text-xs">
                </div>
                <div class="flex flex-col mb-5">
                    <label for="category" class="text-sm text-gray-700 mb-3 font-semibold">Category</label>
                    <select class="text-gray-400 focus:outline-none px-3 py-3 bg-white border border-gray-200 focus:border-theme-blue rounded-md text-xs md:w-1/2">
                        
                    </select>
                </div>
                <div class="flex flex-col mb-5">
                    <label for="category" class="text-sm text-gray-700 mb-3 font-semibold">Answer</label>
                    <textarea cols="30" rows="10" class="text-gray-400 focus:outline-none px-3 py-3 border border-gray-200 focus:border-theme-blue rounded-md text-xs"></textarea>
                </div>
                <div class="flex items-center space-x-1 mb-4">
                    <span><input type="checkbox"></span>
                    <span class="text-xs">Dashboard view</span>
                </div>
                <div class="flex flex-col mb-5">
                    <button class="text-sm tracking-wide text-white px-3 py-3 md:w-1/3 focus:outline-none rounded bg-subscription-dark">Add</button>
                </div>
            </form>
        </div>
    </ModalBox>
</template>

<script>
    import { mapGetters } from 'vuex'
    import FaqRender from './components/FaqRender.vue'
    import ModalBox from './../../components/Modal.vue'

    export default {
        name : 'FAQ',
        components : { FaqRender, ModalBox },
        data () {
            return {
                openFaqModal : false
            }
        },
        computed : {
            ...mapGetters({
                GET_USER_INFORMATION : 'jitcontrol/GET_USER_INFORMATION',
            })
        },
    }
</script>

<style lang="scss" scoped>

</style>